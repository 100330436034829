/*Base Structure*/
.wm-tabs .tabs-content {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-top-style: var(
    --tab-content-border-top-style,
    var(--tab-content-border-style)
  );
  border-top-width: var(
    --tab-content-border-top-width,
    var(--tab-content-border-width)
  );
  border-top-color: var(
    --tab-content-border-top-color,
    var(--tab-content-border-color)
  );
  border-right-style: var(
    --tab-content-border-right-style,
    var(--tab-content-border-style)
  );
  border-right-width: var(
    --tab-content-border-right-width,
    var(--tab-content-border-width)
  );
  border-right-color: var(
    --tab-content-border-right-color,
    var(--tab-content-border-color)
  );
  border-bottom-style: var(
    --tab-content-border-bottom-style,
    var(--tab-content-border-style)
  );
  border-bottom-width: var(
    --tab-content-border-bottom-width,
    var(--tab-content-border-width)
  );
  border-bottom-color: var(
    --tab-content-border-bottom-color,
    var(--tab-content-border-color)
  );
  border-left-style: var(
    --tab-content-border-left-style,
    var(--tab-content-border-style)
  );
  border-left-width: var(
    --tab-content-border-left-width,
    var(--tab-content-border-width)
  );
  border-left-color: var(
    --tab-content-border-left-color,
    var(--tab-content-border-color)
  );
}

.wm-tabs .tabs-content-wrapper {
  display: flex;
}
.wm-tabs .tabs-content article {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  position: relative;
  transform: translateZ(0);
}
.tab-content > .page-section {
  padding-top: 0px !important;
}
.tab-content > .page-section .section-border {
  top: 0px;
}
body:not(.sqs-edit-mode-active) [data-wm-plugin="tabs"] {
  opacity: 0;
  transition: opacity 0.3s ease;
}
body:not(.sqs-edit-mode-active)
  [data-wm-plugin="tabs"][data-loading-state="loaded"] {
  opacity: 1;
}

.wm-tabs .tabs-header {
  display: none;
  position: relative;
  transition: background-color 0.2s ease;
  z-index: 2;
}

/* Tabs Content */
.wm-tabs[data-navigation-type="horizontal"] .tab-panel,
.wm-tabs[data-navigation-type="vertical"] .tab-panel,
.wm-tabs[data-navigation-type="select"] .tab-panel {
  visibility: hidden;
  opacity: 0;
}
.wm-tabs[data-navigation-type="horizontal"] .dragging .tab-panel,
.wm-tabs[data-navigation-type="vertical"] .dragging .tab-panel,
.wm-tabs[data-navigation-type="select"] .dragging .tab-panel {
  visibility: visible;
  opacity: 1;
}
.wm-tabs[data-navigation-type="horizontal"] .tab-panel.active,
.wm-tabs[data-navigation-type="vertical"] .tab-panel.active,
.wm-tabs[data-navigation-type="select"] .tab-panel.active {
  visibility: visible;
  opacity: 1;
}
.wm-tabs[data-navigation-type="horizontal"] .dragging,
.wm-tabs[data-navigation-type="vertical"] .dragging {
}
.wm-tabs[data-navigation-type="horizontal"] .dragging *,
.wm-tabs[data-navigation-type="vertical"] .dragging * {
  user-select: none;
  cursor: grab;
}

/* Tabs Navigation */
.wm-tabs[data-navigation-type="horizontal"] > * > .tabs-content {
  padding-top: var(--tab-content-padding-top, var(--tab-content-padding-start));
}
.wm-tabs[data-navigation-type="horizontal"] > * > .tabs-header {
  border-bottom-width: var(
    --tabs-header-border-bottom-width,
    var(--tabs-nav-border-width)
  );
  border-bottom-style: solid;
  border-bottom-color: var(
    --tabs-header-border-bottom-color,
    var(--tabs-nav-border-color)
  );
  padding-top: 8px;
}
.wm-tabs[data-navigation-type="horizontal"].full-width > * > .tabs-header {
  --nav-padding-right: 2vw;
  --nav-padding-left: 2vw;
  padding-left: var(--nav-padding-left);
  padding-right: var(--nav-padding-right);
}
@media (max-width: 767px) {
  .wm-tabs[data-navigation-type="horizontal"].full-width {
    --nav-padding-left: 6vw;
    --nav-padding-right: 6vw;
  }
}

.wm-tabs[data-navigation-type="horizontal"] > * > .tabs-header {
  display: flex;
  margin: auto;
  justify-content: var(--nav-justify-items);
}
.wm-tabs[data-navigation-type="horizontal"][data-sticky-nav="true"]
  > *
  > .tabs-header {
  position: sticky;
  --top-offset: 0px;
  top: calc(var(--top-offset, 0px) + var(--nav-sticky-offset));
  z-index: 2;
  transition: top 0.2s ease;
}
.wm-tabs[data-navigation-type="horizontal"].is-sticky > * > .tabs-header {
  background: hsla(var(--wm-background-hsl), 1);
}
.wm-tabs[data-navigation-type="horizontal"] .nav-container {
  max-width: var(--nav-max-width);
  width: 100%;
}
.wm-tabs[data-navigation-type="horizontal"] nav {
  display: flex;
  position: relative;
  z-index: 0;
  width: 100%;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  gap: var(--tabs-header-gap);
  padding-bottom: var(--nav-padding-bottom);
  border-radius: var(--nav-border-radius);
  background-color: var(--nav-background-color);
  box-sizing: border-box;
}
.wm-tabs[data-navigation-type="horizontal"] nav::-webkit-scrollbar {
  display: var(--nav-scrollbar-display);
  position: absolute;
  height: var(--nav-scrollbar-size);
}
.wm-tabs[data-navigation-type="horizontal"] nav::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 2px;
  transition: background-color 0.3s ease;
}
.wm-tabs[data-navigation-type="horizontal"] nav::-webkit-scrollbar-track {
  background-color: var(--nav-scrollbar-track-color);
}
.wm-tabs[data-navigation-type="horizontal"] nav:hover::-webkit-scrollbar-thumb {
  background-color: var(--nav-scrollbar-color);
}

/* Vertical Tabs */
.wm-tabs[data-navigation-type="vertical"] > * > .tabs-content {
  padding-left: var(
    --tab-content-padding-left,
    var(--tab-content-padding-start)
  );
}
.wm-tabs[data-navigation-type="vertical"] > .tabs-wrapper {
  display: grid;
  grid-template-columns: var(--vertical-tabs-navbar-width) 1fr;
}
.wm-tabs[data-navigation-type="vertical"] > * > .tabs-header {
  border-right-width: var(
    --tabs-header-border-right-width,
    var(--tabs-nav-border-width)
  );
  border-right-style: solid;
  border-right-color: var(
    --tabs-header-border-right-color,
    var(--tabs-nav-border-color)
  );
  display: flex;
  margin: auto;
  box-sizing: border-box;
  justify-content: var(--nav-justify-items);
  height: 100%;
  width: 100%;
}
.wm-tabs[data-navigation-type="vertical"] .nav-container {
  width: 100%;
}
.wm-tabs[data-navigation-type="vertical"] nav {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  width: 100%;
  height: auto;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  gap: var(--tabs-header-gap);
  padding-right: var(--nav-padding-right);
  border-radius: var(--nav-border-radius);
  background-color: var(--nav-background-color);
  box-sizing: border-box;
  max-height: calc(100vh - 17px);
}
.wm-tabs[data-navigation-type="vertical"][data-sticky-nav="true"].is-sticky
  > *
  > .tabs-header
  nav {
  position: sticky;
  top: calc(var(--top-offset, 0px) + var(--nav-sticky-offset));
  z-index: 2;
  transition: top 0.2s ease;
}
.wm-tabs[data-navigation-type="vertical"] nav::-webkit-scrollbar {
  display: var(--nav-scrollbar-display);
  position: absolute;
  width: var(--nav-scrollbar-size);
}
.wm-tabs[data-navigation-type="vertical"] nav::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 2px;
  transition: background-color 0.3s ease;
}
.wm-tabs[data-navigation-type="vertical"] nav::-webkit-scrollbar-track {
  background-color: var(--nav-scrollbar-track-color);
}
.wm-tabs[data-navigation-type="vertical"] nav:hover::-webkit-scrollbar-thumb {
  background-color: var(--nav-scrollbar-color);
}

/*Tab Navigation Buttons (Vertical & Horizontal)*/
.wm-tabs nav button {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: var(--tab-button-flex-direction);
  align-items: var(--tab-button-align-items);
  justify-content: var(--tab-button-justify-content);
  gap: 8px;
  flex-shrink: 0;
  padding: var(--tab-button-padding-y) var(--tab-button-padding-x);
  background-color: var(--tab-button-background);
  border-style: solid;
  border-width: var(--tab-button-border-width);
  border-color: var(--tab-button-border-color);
  border-radius: var(--tab-button-border-radius);
  color: var(--tab-button-color);
  transition: background-color 0.2s ease, color 0.2s ease;
  box-sizing: border-box;
}
.wm-tabs nav button:focus {
  outline-offset: -2px;
}

.wm-tabs nav .tab-title {
  margin: 0px;
  font-size: var(--tab-button-font-size);
  font-weight: var(--tab-button-font-weight);
  text-transform: var(--tab-button-text-transform);
  font-family: var(--tab-button-font-family, var(--heading-font-font-family, inherit));
  color: inherit;
}
.wm-tabs nav button:hover .tab-title {
  opacity: var(--tab-button-hover-opacity);
}
.wm-tabs nav button:hover .tab-title {
}
.wm-tabs nav button.active {
  opacity: 1;
  background-color: var(--tab-button-active-background);
  border-color: var(--tab-button-active-border-color);
  border-style: solid;
  color: var(--tab-button-active-color);
}
.wm-tabs nav button.active .tab-title {
  color: inherit;
  opacity: 1;
  font-weight: var(--tab-button-active-font-weight);
}

.wm-tabs nav .tab-button-image {
  aspect-ratio: var(--tab-button-image-aspect-ratio);
  position: relative;
  width: var(--tab-button-image-width);
  margin-block: auto;
  overflow: hidden;
  border-radius: var(--tab-button-image-border-radius);
}
.wm-tabs nav .tab-button-image img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: scale 1s ease;
}
.wm-tabs nav button.active .tab-button-image img,
.wm-tabs nav button:hover .tab-button-image img {
  scale: 1.06;
}
.wm-tabs nav .tab-button-image .img-spacer {
  display: block;
  opacity: 0;
  height: 0px;
  padding-bottom: calc(100% / var(--tab-button-image-aspect-ratio));
  width: 100%;
  object-fit: cover;
  transition: scale 1s ease;
}

/*Nav Active Indicator*/
.wm-tabs .tabs-header .indicator-track {
  display: var(--tab-indicator-display);
  position: absolute;
  background: var(--tab-indicator-track-background);
  z-index: 0;
}
.wm-tabs[data-navigation-type="horizontal"]
  > *
  > .tabs-header
  .indicator-track {
  height: var(--tab-indicator-track-size);
  width: var(--nav-full-width, var(--nav-scroll-width));
  bottom: 0px;
  left: 0px;
}
.wm-tabs[data-navigation-type="vertical"] > * > .tabs-header .indicator-track {
  height: var(--nav-full-height, var(--nav-scroll-height));
  width: var(--tab-indicator-track-size);
  top: 0px;
  right: 0px;
}
.wm-tabs .tabs-header .active-indicator {
  position: absolute;
  top: var(--active-indicator-top);
  left: var(--active-indicator-left);
  width: var(--active-indicator-width);
  height: var(--active-indicator-height);
  background: var(--active-indicator-color);
  border-radius: var(--active-indicator-border-radius);
  border-width: var(--active-indicator-border-width);
  border-color: var(--active-indicator-border-color);
  border-style: solid;
  box-sizing: border-box;
}
[data-loading-state="loaded"].wm-tabs .tabs-header .active-indicator {
  transition: left 0.3s ease, top 0.3s ease, width 0.3s ease, height 0.3s ease;
}

/*Nav Scroll Indicators*/
.wm-tabs .tabs-header .scroll-indicator-container {
  display: none;
}
.wm-tabs[data-navigation-type="horizontal"]
  > *
  > .tabs-header
  .scroll-indicator-container,
.wm-tabs[data-navigation-type="vertical"]
  > *
  > .tabs-header
  .scroll-indicator-container {
  display: contents;
}
.wm-tabs .tabs-header .scroll-indicator-container {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.wm-tabs[data-navigation-type="horizontal"]
  > *
  > .tabs-header
  .scroll-indicator-container {
  max-width: var(--nav-max-width);
}

.wm-tabs .tabs-header .scroll-indicator {
  display: grid;
  place-items: center;
  position: absolute;
  z-index: 1;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s ease 0.3s;
  background: unset;
  border: unset;
}
.wm-tabs[data-navigation-type="horizontal"]
  > *
  > .tabs-header
  .scroll-indicator {
  top: 50%;
  transform: translate(0px, -50%);
  height: 100%;
  width: 40px;
  padding-bottom: var(--nav-padding-bottom);
}
.wm-tabs[data-navigation-type="horizontal"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-start {
  transform: translate(0px, -50%);
  left: 0px;
  background-image: linear-gradient(
    to left,
    hsla(0, 0%, 0%, 0%) 00%,
    var(--siteBackgroundColor) 50%
  );
}
.wm-tabs[data-navigation-type="horizontal"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-end {
  transform: translate(0px, -50%);
  right: 0px;
  background-image: linear-gradient(
    to right,
    hsla(0, 0%, 0%, 0%) 0%,
    var(--siteBackgroundColor) 50%
  );
}

.wm-tabs[data-navigation-type="vertical"] > * > .tabs-header .scroll-indicator {
  left: 50%;
  transform: translate(-50%, 0px);
  height: 40px;
  width: 100%;
  padding-right: var(--nav-padding-right);
}
.wm-tabs[data-navigation-type="vertical"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-start {
  transform: translate(-50%, 0px);
  top: 0px;
  background-image: linear-gradient(
    to top,
    hsla(0, 0%, 0%, 0%) 00%,
    var(--siteBackgroundColor) 50%
  );
}
/*Until I can fix sticky scrolling*/
.wm-tabs[data-navigation-type="vertical"]
  > *
  > .tabs-header
  .scroll-indicator-container {
  display: none;
}
.wm-tabs[data-navigation-type="vertical"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-end {
  transform: translate(-50%, 0px);
  top: calc(100vh - 57px);
  background-image: linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0%) 0%,
    var(--siteBackgroundColor) 50%
  );
}
.wm-tabs .tabs-header .scroll-indicator svg {
  color: var(--scroll-indicator-color);
  height: var(--scroll-indicator-size);
  width: var(--scroll-indicator-size);
  opacity: 0.7;
  transition: opacity 0.3s ease;
}
.wm-tabs[data-navigation-type="vertical"]
  > *
  > .tabs-header
  .scroll-indicator
  svg {
  rotate: 90deg;
}
.wm-tabs .tabs-header .scroll-indicator:hover svg {
  opacity: 1;
}
.wm-tabs[data-navigation-indicators="start"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-end {
  opacity: 0;
  visibility: hidden;
  transform: translate(0px, -50%);
}
.wm-tabs[data-navigation-indicators="start"][data-navigation-type="vertical"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-end {
  transform: translate(-50%, 0px);
}
.wm-tabs[data-navigation-indicators="start"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-start {
  display: grid;
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s ease 0s;
}
.wm-tabs[data-navigation-indicators="end"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-end {
  display: grid;
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s ease 0s;
}
.wm-tabs[data-navigation-indicators="end"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-start {
  opacity: 0;
  visibility: hidden;
  transform: translate(0px, -50%);
}
.wm-tabs[data-navigation-indicators="end"][data-navigation-type="vertical"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-start {
  transform: translate(-50%, 0px);
}
.wm-tabs[data-navigation-indicators="both"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-end {
  display: grid;
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s ease 0s;
}
.wm-tabs[data-navigation-indicators="both"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-start {
  display: grid;
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s ease 0s;
}
.wm-tabs[data-navigation-indicators="none"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-end {
  opacity: 0;
  visibility: hidden;
  transform: translate(0px, -50%);
}
.wm-tabs[data-navigation-indicators="none"]
  > *
  > .tabs-header
  .scroll-indicator.indicator-start {
  opacity: 0;
  visibility: hidden;
  transform: translate(0px, -50%);
}

/*===== Select Navigation =====*/
.wm-tabs[data-navigation-type="select"] > * > .tabs-content {
  padding-top: var(--tab-content-padding-top, var(--tab-content-padding-start));
}
.wm-tabs .select-navigation-container {
  display: none;
  position: relative;
  max-width: var(--nav-max-width);
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
}
.wm-tabs[data-navigation-type="select"] > * > .select-navigation-container {
  display: block;
}
.wm-tabs[data-navigation-type="select"][data-sticky-nav="true"]
  > *
  > .select-navigation-container {
  position: sticky;
  top: calc(var(--top-offset, 0px) + var(--nav-sticky-offset));
  z-index: 2;
  transition: top 0.2s ease, padding 0.2s ease;
}
.wm-tabs[data-navigation-type="select"].is-sticky
  > *
  > .select-navigation-container {
  background: hsla(var(--wm-background-hsl), 1);
  /* padding: 17px 1px; */
}
.wm-tabs.full-width .select-navigation-container {
  padding-left: var(--nav-padding-left);
  padding-right: var(--nav-padding-right);
}
.wm-tabs .select-navigation {
  border-radius: var(--select-navigation-button-border-radius);
}

.select-navigation {
  position: relative;
}
.select-navigation .select-button-container button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--select-navigation-button-background);
  color: var(--select-navigation-button-color);
  border-style: solid;
  border-width: var(--select-navigation-button-border-width);
  border-color: var(--select-navigation-button-border-color);
  border-radius: var(--select-navigation-button-border-radius);
  font-family: var(--select-button-font-family, inherit);
  font-size: var(--select-button-font-size);
  padding: var(--select-button-padding-y) var(--select-button-padding-x);
}
.select-navigation .select-button-container svg {
  width: 0.9rem;
  height: 0.9rem;
}
.select-navigation .select-items-container {
  position: absolute;
  margin-top: 4px;
  z-index: 9;
  width: 100%;
  box-shadow: 0.3px 0.5px 0.7px hsl(0deg 0% 0% / 0.11),
    0.8px 1.6px 2px -0.8px hsl(0deg 0% 0% / 0.11),
    2.1px 4.1px 5.2px -1.7px hsl(0deg 0% 0% / 0.11),
    5px 10px 12.6px -2.5px hsl(0deg 0% 0% / 0.11);
  overflow: hidden;
  border-radius: 5px;
  box-sizing: border-box;
  border-style: solid;
  border-color: var(--select-menu-border-color);
  border-width: var(--select-menu-border-width);
}
.select-navigation .select-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 4px 0px;
  background: var(--select-menu-background-color);
}
.select-navigation button.select-item {
  text-align: start;
  width: 100%;
  background-color: var(--select-button-background);
  color: var(--select-button-color);
  border-width: 0px;
  padding: 4px 8px;
  font-size: var(--select-button-font-size);
  transition: background-color 0.1s ease;
}
.select-navigation button.select-item:hover {
  background-color: var(--select-button-hover-background);
}
.select-navigation button.select-item.active {
  color: var(--select-button-active-color);
}

.select-navigation .select-items-container {
  display: none;
}
.select-navigation.open {
  outline: 2px solid var(--secondaryButtonBackgroundColor);
}
.select-navigation.open .select-items-container {
  display: block;
}

/*Click & Drag*/
.wm-tabs[data-allow-click-and-drag="true"]
  .gallery-masonry
  .gallery-masonry-item[data-loaded]
  img,
.wm-tabs[data-allow-click-and-drag="true"] .gallery-grid-item img,
.wm-tabs[data-allow-click-and-drag="true"] .sqs-block-image img,
.wm-tabs[data-allow-click-and-drag="true"]
  .sqs-block-summary-v2
  .summary-thumbnail-container
  img {
  user-select: none;
  pointer-events: none;
}

/*Edit Mode*/
body.sqs-edit-mode-active .sqs-block-code:has([data-wm-plugin="tabs"]) {
  border: 1px dashed currentColor;
  box-sizing: border-box;
}
body.sqs-edit-mode-active .sqs-block-code:has([data-wm-plugin="tabs"])::after {
  content: "Tabs Plugin Settings";
  position: absolute;
  font-size: 10px;
  padding: 0px 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--siteBackgroundColor);
  color: var(--headingExtraLargeColor);
  box-sizing: border-box;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}
body.sqs-edit-mode-active
  .sqs-block.sqs-block-code:has([data-wm-plugin="tabs"]):hover::after {
  opacity: 1;
}

/*Force Images*/
.tab-content .sqs-block-calendar img[data-parent-ratio],
.tab-content .sqs-block-gallery img[data-parent-ratio],
.tab-content .sqs-block-summary-v2 .summary-block-wrapper:not(.sqs-gallery-design-autocolumns) img[data-parent-ratio] {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  position: absolute !important;
  object-position: var(--wm-focal-point, center) !important;
}
.tab-content .sqs-block-gallery .content-fit img[data-parent-ratio]{
  object-position: unset !important;
}

.tab-content .sqs-block-gallery .image-slide-anchor{
  position: relative;
}
.tab-content .sqs-block-gallery .content-fit img[data-parent-ratio]{
  object-fit: contain !important;
}

/*Layout Engine Inside Fluid Engine*/
.tab-content .sqs-layout .row > .sqs-block,
.tab-content .sqs-layout .col > .sqs-block {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 17px;
  padding-bottom: 17px;
  display: block;
  box-sizing: border-box;
}

/*Override global Animations*/
[data-override-initial-global-animation].slideIn,
[data-override-initial-global-animation].fadeIn,
[data-override-initial-global-animation].scaleIn,
[data-override-initial-global-animation].flexIn,
[data-override-initial-global-animation].preFade,
[data-override-initial-global-animation].preScale,
[data-override-initial-global-animation].preFlex,
[data-override-initial-global-animation].preSlide {
  opacity: 1;
  transform: initial;
  clip-path: initial;
}

/*Shape Block Overrides*/
.tab-content [data-stretched-to-fill] > .sqs-shape {
  width: 100%;
  height: 100%;
}

/*Edge To Edge*/
.wm-tabs.edge-to-edge .tab-content > section .fluid-engine {
  grid-template-columns: 0 repeat(8, minmax(0, var(--cell-max-width))) 0;
  margin-left: calc(-1 * var(--wm-column-gap));
  margin-right: calc(-1 * var(--wm-column-gap));
}
@media(min-width: 767px) {
  .wm-tabs.edge-to-edge .tab-content > section .fluid-engine {
    grid-template-columns: 0px repeat(24, minmax(0, var(--cell-max-width))) 0px;
  }
}

/*Default Settings*/
.wm-tabs {
  /*Tabs Navigation*/
  --tabs-nav-border-color: hsla(var(--wm-text-hsl), 50%);
  --tabs-nav-border-width: 2px;

  --tabs-header-gap: 8px;
  --nav-padding-bottom: 8px;
  --nav-padding-right: 8px;
  --nav-justify-items: center;
  --nav-max-width: var(--sqs-site-max-width);
  --nav-border-radius: 0px;
  --nav-background-color: transparent;

  --tab-button-align-items: center;
  --tab-button-justify-content: center;
  --tab-button-flex-direction: column;
  --tab-button-padding-y: 8px;
  --tab-button-padding-x: 18px;
  --tab-button-font-size: inherit;
  --tab-button-font-weight: inherit;
  --tab-button-border-radius: 5px;
  --tab-button-border-width: 0px;
  --tab-button-border-color: currentColor;
  --tab-button-background: transparent;
  --tab-button-color: currentColor;

  --tab-button-hover-opacity: 0.6;

  --tab-button-active-font-weight: inherit;
  --tab-button-active-border-color: currentColor;
  --tab-button-active-background: transparent;
  --tab-button-active-color: hsla(var(--wm-accent-inverse-hsl), 1);

  --tab-indicator-display: block;
  --tab-indicator-track-background: transparent;
  --tab-indicator-track-size: 100%;
  --active-indicator-top: var(--tab-button-active-top);
  --active-indicator-left: var(--tab-button-active-left);
  --active-indicator-height: var(--tab-button-active-height);
  --active-indicator-width: var(--tab-button-active-width);
  --active-indicator-color: hsla(var(--wm-accent-hsl), 1);
  --active-indicator-border-radius: var(--tab-button-border-radius);
  --active-indicator-border-color: currentColor;
  --active-indicator-border-width: 0px;

  --tab-button-image-aspect-ratio: 1;
  --tab-button-image-width: 150px;
  --tab-button-image-border-radius: var(--tab-button-border-radius);

  --scroll-indicator-size: 25px;
  --scroll-indicator-color: hsla(var(--wm-text-hsl), 100%);

  --nav-scrollbar-display: block;
  --nav-scrollbar-size: 4px;
  --nav-scrollbar-height: var(--nav-scrollbar-size);
  --nav-scrollbar-width: var(--nav-scrollbar-size);
  --nav-scrollbar-color: hsla(var(--wm-text-hsl), 25%);
  --nav-scrollbar-track-color: hsla(var(--wm-text-hsl), 10%);

  /*Vertical Navigation*/
  --vertical-tabs-navbar-width: 250px;

  /*Select Navigation*/
  --select-menu-background-color: var(--siteBackgroundColor);
  --select-menu-border-color: hsla(var(--lightAccent-hsl), 1);
  --select-menu-border-width: 1px;

  --select-navigation-button-background: transparent;
  --select-navigation-button-color: currentColor;
  --select-navigation-button-border-width: 1px;
  --select-navigation-button-border-color: hsla(var(--wm-text-hsl), 0.25);
  --select-navigation-button-border-radius: 5px;

  --select-button-color: currentColor;
  --select-button-background: transparent;
  --select-button-font-size: 1rem;
  --select-button-hover-background: hsla(var(--wm-text-hsl), 0.25);
  --select-button-active-color: hsla(var(--wm-accent-hsl), 1);

  --select-button-padding-y: 8px;
  --select-button-padding-x: 12px;

  /*Content*/
  --tab-content-padding-start: 8px;
  --tab-content-border-color: currentColor;
  --tab-content-border-width: 0px;
  --tab-content-border-style: solid;
}

:root .wm-tabs {
  --wm-accent-hsl: var(--accent-hsl);
  --wm-accent-inverse-hsl: var(--safeInverseAccent-hsl);
  --wm-background-hsl: var(--white-hsl);
  --wm-text-hsl: var(--black-hsl);
}
